
import { defineComponent, ref, computed } from "vue";

import services from "../../../axios/dbManag";
import { useRoute, useRouter } from "vue-router";

import { useStore } from "vuex";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { alertFailed } from "@/composables/swAlert";
import { stampaTesseraGold } from "@/requests/tesseratoUnaSocRequests";

import isEnabled from "@/composables/isEnabled.js";

export default defineComponent({
  name: "dettaglio-persona",
  components: { Loading },
  setup() {
    const store = useStore();
    // routing
    const router = useRouter(); //usato per redirect
    const route = useRoute(); // prende id
    // Creazione persona
    const persona = ref({ regione_residenza: "", prov_residenza: "" });
    const id = ref(route.params.id);
    // const fromPersone = ref(route.params.fromPersone);
    const isLoaded = ref(false);
    const isError = ref(false);
    // const read_only = ref(false);

    const getFormatDate = (date) => {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, date.length);

      return `${day}/${month}/${year}`;
    };
    const data_nascita = ref("");

    const personaView = () => {
      isLoaded.value = false;
      services.dbManag
        .post(`/anagrafiche/persone/view`, {
          id: id.value,
        })
        .then((res) => {
          persona.value = { ...res.data.results[0] };
          data_nascita.value = getFormatDate(res.data.results[0].data_nascita);
          // read_only.value = res.data.read_only;
        })
        .then(() => {
          store.dispatch("lookupsRegProvCom", {
            selectedRegione: persona.value.regione_residenza,
            selectedProvincia: persona.value.prov_residenza,
            // persona: persona.value
          });
          isLoaded.value = true;
        })
        .catch(() => {
          isError.value = true;
          /* Swal.fire({
            text: "Attenzione! Si è verificato un errore. Riprovare più tardi",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Riprova",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(() => {
            router.push({ name: "persone" });
          }); */
        });
    };
    personaView();

    const isLoading = ref(false);

    const stampaGold = async () => {
      isLoading.value = true;
      await stampaTesseraGold(id.value).then((res) => {
        isLoading.value = false;
        if (res.status == 200) {
          console.log("status 200");
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
      });
    };

    const pushToTesseraGold = (id, tessera) => {
      router.push(
        `/persone/dettaglio-persona/verifica-tessera/${id}?k=${tessera}`
      );
    };
    const fromPersone = ref(false);
    const fromSocieta = ref(false);
    const id_societa = ref(null);

    return {
      route,
      pushToTesseraGold,
      persona,
      id,
      isLoaded,
      isError,
      selectedProvinciaPersona: computed(
        () => store.getters.selectedProvinciaPersona
      ),
      stampaGold,
      isLoading,
      fromPersone,
      fromSocieta,
      id_societa,
      personaView,
      data_nascita,
      // read_only
      isEnabled,
    };
  },
});
